import React, {Component, useEffect, useMemo, useState} from "react";
import {ReactComponent as ChatSvg} from "../../../assets/media/heroicons/outline/chat.svg";
import {ReactComponent as CreatorChat} from "../../../assets/media/icons/creatorChat.svg";
import {ReactComponent as VerticalOptionDots} from "../../../assets/media/icons/verticaloptiondots.svg";
import {ReactComponent as BackToList} from "../../../assets/media/icons/backtolist.svg";
import {Dropdown} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Avatar from "react-avatar";
import {
    getProfileInfoTC,
    setBusinessDocsStatus,
    setBusinessDocsStatusTC,
    setNewChatUser
} from "../../../Redux/users-reducer";
import {LightgalleryItem, LightgalleryProvider} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import {selectNewChatTypeAC} from "../../../Redux/messages-reducer";

export const FriendProfile = (props) => {
    const allUsers = useSelector(state => state.usersPage.usersList)
    const profileInfo = useSelector(state => state.usersPage.profileInfo)
    const businessDocsStatusRedux = useSelector(state => state.usersPage.businesDocStatus)
    const dispatch = useDispatch();
    const key = localStorage.getItem("tokenKey")
    useEffect(() => {
        dispatch(getProfileInfoTC(key, props.hashUser))
    }, [])

    const confirmDocumentsAndBA = (status) => {
        dispatch(setBusinessDocsStatusTC(key, props.hashUser, status))
    }
    const [state, setState] = useState('unknown')

    const [social, setSocial] = useState(profileInfo.social)

    useEffect(() => {
        setSocial(profileInfo.social)
    }, [profileInfo.social])

    const onClickHandler = (user) => {
        dispatch(selectNewChatTypeAC('support'))
        dispatch(setNewChatUser(user))
    }
    const onClickHandler2 = (user) => {
        dispatch(selectNewChatTypeAC('creator'))
        dispatch(setNewChatUser(user))
    }

    return (
        <main className={"main main-visible"}>
            <div className="friends px-0 py-2 p-xl-3">
                <div className="container-xl">
                    <div className="row">
                        <div className="col">
                            <div className="card card-body card-bg-1 mb-3">
                                <div className="d-flex flex-column align-items-center">
                                    <div className="avatar avatar-lg mb-3">
                                        {
                                            profileInfo &&
                                            <Avatar
                                                size={80}
                                                src={profileInfo.avatar}
                                                name={profileInfo.email}
                                                round
                                            />
                                        }
                                    </div>

                                    <div className="d-flex flex-column align-items-center">
                                        <h5 className="mb-1">
                                            {
                                                profileInfo && profileInfo.firstName + " " + profileInfo.lastName
                                            }
                                            {
                                                profileInfo && profileInfo.firstName === "" && profileInfo.lastName === '' && profileInfo.nickname
                                            }
                                        </h5>
                                        <div className="d-flex mt-2">
                                            <Link
                                                onClick={() => {
                                                    onClickHandler(profileInfo)
                                                }}
                                                to={
                                                    profileInfo && profileInfo.supportChatId == 0 ? '/Chat/new' : '/Chat/' + profileInfo.supportChatId
                                                }
                                                className="btn btn-primary btn-icon rounded-circle text-light mx-2">
                                                <ChatSvg/>
                                            </Link>
                                            <Link
                                                onClick={() => {
                                                    onClickHandler2(profileInfo)
                                                }}
                                                to={
                                                    profileInfo && profileInfo.creatorChatId == 0 ? '/Chat/new' : '/Chat/' + profileInfo.creatorChatId
                                                }
                                                className="btn btn-primary btn-icon rounded-circle text-light mx-2">
                                                <CreatorChat style={{fill: '#fff', width: 25}}/>
                                            </Link>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div className="card-options">
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            className="text-muted hw-20 mt-2"
                                            as={VerticalOptionDots}
                                        />
                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                            <Link className="dropdown-item" to="#">
                                                Remove
                                            </Link>
                                            <Link className="dropdown-item" to="#">
                                                Block
                                            </Link>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="chat-closer d-xl-none">
                                    <Link to={'/Users'}
                                          className="btn btn-secondary btn-icon btn-minimal btn-sm text-muted"
                                          type="button"
                                          data-close=""
                                    >
                                        <BackToList/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row friends-info">
                        <div className="col">
                            <div className="card">
                                <ul className="list-group list-group-flush">
                                    {
                                        profileInfo !== undefined &&
                                        <li className="list-group-item">
                                            <div className="media align-items-center">
                                                <div className="media-body">
                                                    <p className="small text-muted mb-0">Nickname</p>
                                                    <p className="mb-0">
                                                        @{profileInfo.nickname}
                                                    </p>
                                                </div>
                                                {/*<ClockSvg className="text-muted hw-20"/>*/}
                                            </div>
                                        </li>
                                    }
                                    {
                                        profileInfo !== undefined &&
                                        <li className="list-group-item">
                                            <div className="media align-items-center">
                                                <div className="media-body">
                                                    <p className="small text-muted mb-0">Email</p>
                                                    <p className="mb-0">
                                                        {profileInfo.email}
                                                    </p>
                                                </div>
                                                {/*<MailSvg className="text-muted hw-20"/>*/}
                                            </div>
                                        </li>
                                    }
                                    {
                                        profileInfo !== undefined &&
                                        <li className="list-group-item">
                                            <div className="media align-items-center">
                                                <div className="media-body">
                                                    <p className="small text-muted mb-0">Invite code</p>
                                                    <p className="mb-0">
                                                        {profileInfo.invite}
                                                    </p>
                                                </div>
                                                {/*<MailSvg className="text-muted hw-20"/>*/}
                                            </div>
                                        </li>
                                    }
                                    {
                                        profileInfo !== undefined &&
                                        <li className="list-group-item">
                                            <div className="media align-items-center">
                                                <div className="media-body">
                                                    <p className="small text-muted mb-0">Reg invite code</p>
                                                    <p className="mb-0">
                                                        {profileInfo.regInvite}
                                                    </p>
                                                </div>
                                                {/*<MailSvg className="text-muted hw-20"/>*/}
                                            </div>
                                        </li>
                                    }
                                    {
                                        profileInfo !== undefined &&
                                        <li className="list-group-item">
                                            <div className="media align-items-center">
                                                <div className="media-body">
                                                    <p className="small text-muted mb-0">Withdraw</p>
                                                    <p className="mb-0">
                                                        {profileInfo.withdrawAmount}
                                                    </p>
                                                </div>
                                                {/*<MailSvg className="text-muted hw-20"/>*/}
                                            </div>
                                        </li>
                                    }


                                </ul>
                            </div>

                            <div className="card">
                                <ul className="list-group list-group-flush">
                                    {
                                        profileInfo !== undefined &&
                                        <li className="list-group-item">
                                            <div className="media align-items-center">
                                                <div className="media-body">
                                                    <p className="small text-muted mb-0">Business account</p>
                                                    <p className="mb-0">
                                                        {profileInfo.isBusiness === 1 ? 'True' : 'False'}
                                                    </p>
                                                </div>
                                                {/*<MailSvg className="text-muted hw-20"/>*/}
                                            </div>
                                        </li>
                                    }
                                    <li className="list-group-item">
                                        <div className="media align-items-center">
                                            <div className="media-body">
                                                <p className="small text-muted mb-0">Business account documents</p>
                                                <p className="mb-1" style={{display: 'flex', paddingTop: 6}}>
                                                    <LightgalleryProvider>
                                                        {

                                                            profileInfo.bankDocList !== undefined && profileInfo.bankDocList.map((doc) => {
                                                                let path = 'https://cdn.fanter.com/' + doc.filePath
                                                                return (
                                                                    <LightgalleryItem
                                                                        group={"group1"}
                                                                        key={path}
                                                                        src={path}
                                                                        thumb={path}
                                                                    >
                                                                        <img
                                                                            alt="photo1"
                                                                            src={path}
                                                                            style={{
                                                                                width: "100px",
                                                                                height: '100%',
                                                                                objectFit: 'cover',
                                                                                marginRight: 10,
                                                                                borderRadius: 4
                                                                            }}
                                                                        />
                                                                    </LightgalleryItem>
                                                                )
                                                            })

                                                        }
                                                    </LightgalleryProvider>
                                                </p>
                                                <div style={{display: "flex"}}>
                                                    <div className="form-group rounded"
                                                         style={{marginRight: 10, marginBottom: 0, marginTop: 5}}>
                                                        <div className="custom-control custom-radio">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="exampleRadios"
                                                                value="option1"
                                                                checked={businessDocsStatusRedux === 2}
                                                                onChange={() => {

                                                                }}
                                                                onClick={() => {
                                                                    confirmDocumentsAndBA(2)
                                                                    setState('2')
                                                                }}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                onClick={() => {
                                                                    confirmDocumentsAndBA(2)
                                                                    setState('2')
                                                                }}
                                                            >
                                                                On check
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group rounded"
                                                         style={{marginRight: 10, marginBottom: 0, marginTop: 5}}>
                                                        <div className="custom-control custom-radio">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="exampleRadios"
                                                                value={'option2'}
                                                                checked={businessDocsStatusRedux === 4}
                                                                onClick={() => {
                                                                    confirmDocumentsAndBA(4)
                                                                    setState('4')
                                                                }}
                                                                onChange={() => {

                                                                }}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                onClick={() => {
                                                                    confirmDocumentsAndBA(4)
                                                                    setState('4')
                                                                }}
                                                            >
                                                                Rejected
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group rounded"
                                                         style={{marginRight: 10, marginBottom: 0, marginTop: 5}}>
                                                        <div className="custom-control custom-radio">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="exampleRadios"
                                                                value="option2"
                                                                checked={businessDocsStatusRedux === 3}
                                                                onChange={() => {

                                                                }}
                                                                onClick={() => {
                                                                    confirmDocumentsAndBA(3)
                                                                    setState('3')
                                                                }}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                onClick={() => {
                                                                    confirmDocumentsAndBA(3)
                                                                    setState('3')
                                                                }}
                                                            >
                                                                Сonfirmed
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<ClockSvg className="text-muted hw-20"/>*/}
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className="card">
                                <ul className="list-group list-group-flush">
                                    {
                                        profileInfo.bankOptions != undefined && profileInfo.bankOptions.length > 0 && profileInfo.bankOptions[0].payOnCard === "0" &&
                                        <>
                                            <li className="list-group-item">
                                                <div className="media align-items-center">
                                                    <div className="media-body">
                                                        <p className="small text-muted mb-0">id</p>
                                                        <p className="mb-0">
                                                            {profileInfo.bankOptions[0].id}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item">
                                                <div className="media align-items-center">
                                                    <div className="media-body">
                                                        <p className="small text-muted mb-0">userId</p>
                                                        <p className="mb-0">
                                                            {profileInfo.bankOptions[0].userId}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item">
                                                <div className="media align-items-center">
                                                    <div className="media-body">
                                                        <p className="small text-muted mb-0">fio</p>
                                                        <p className="mb-0">
                                                            {profileInfo.bankOptions[0].fio}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item">
                                                <div className="media align-items-center">
                                                    <div className="media-body">
                                                        <p className="small text-muted mb-0">Банк</p>
                                                        <p className="mb-0">
                                                            {profileInfo.bankOptions[0].bank}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item">
                                                <div className="media align-items-center">
                                                    <div className="media-body">
                                                        <p className="small text-muted mb-0">Номер счета</p>
                                                        <p className="mb-0">
                                                            {profileInfo.bankOptions[0].numberAccount}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item">
                                                <div className="media align-items-center">
                                                    <div className="media-body">
                                                        <p className="small text-muted mb-0">Корр. счет</p>
                                                        <p className="mb-0">
                                                            {profileInfo.bankOptions[0].korrBank}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item">
                                                <div className="media align-items-center">
                                                    <div className="media-body">
                                                        <p className="small text-muted mb-0">Бик</p>
                                                        <p className="mb-0">
                                                            {profileInfo.bankOptions[0].bikBank}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item">
                                                <div className="media align-items-center">
                                                    <div className="media-body">
                                                        <p className="small text-muted mb-0">ИНН Банка</p>
                                                        <p className="mb-0">
                                                            {profileInfo.bankOptions[0].innBank}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item">
                                                <div className="media align-items-center">
                                                    <div className="media-body">
                                                        <p className="small text-muted mb-0">КПП Банка</p>
                                                        <p className="mb-0">
                                                            {profileInfo.bankOptions[0].kppBank}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item">
                                                <div className="media align-items-center">
                                                    <div className="media-body">
                                                        <p className="small text-muted mb-0">Дата рождения</p>
                                                        <p className="mb-0">
                                                            {profileInfo.bankOptions[0].birthDay}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item">
                                                <div className="media align-items-center">
                                                    <div className="media-body">
                                                        <p className="small text-muted mb-0">Страна</p>
                                                        <p className="mb-0">
                                                            {profileInfo.bankOptions[0].country}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item">
                                                <div className="media align-items-center">
                                                    <div className="media-body">
                                                        <p className="small text-muted mb-0">Город</p>
                                                        <p className="mb-0">
                                                            {profileInfo.bankOptions[0].town}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item">
                                                <div className="media align-items-center">
                                                    <div className="media-body">
                                                        <p className="small text-muted mb-0">Адрес1</p>
                                                        <p className="mb-0">
                                                            {profileInfo.bankOptions[0].address1}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item">
                                                <div className="media align-items-center">
                                                    <div className="media-body">
                                                        <p className="small text-muted mb-0">Адрес2</p>
                                                        <p className="mb-0">
                                                            {profileInfo.bankOptions[0].address2}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item">
                                                <div className="media align-items-center">
                                                    <div className="media-body">
                                                        <p className="small text-muted mb-0">Индекс</p>
                                                        <p className="mb-0">
                                                            {profileInfo.bankOptions[0].zipCode}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item">
                                                <div className="media align-items-center">
                                                    <div className="media-body">
                                                        <p className="small text-muted mb-0">Штат</p>
                                                        <p className="mb-0">
                                                            {profileInfo.bankOptions[0].addressState}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                        </>
                                    }
                                    {
                                        profileInfo.bankOptions != undefined && profileInfo.bankOptions.length > 0 && profileInfo.bankOptions[0].payOnCard === "1" &&
                                        <>
                                            <li className="list-group-item">
                                                <div className="media align-items-center">
                                                    <div className="media-body">
                                                        <p className="small text-muted mb-0">id</p>
                                                        <p className="mb-0">
                                                            {profileInfo.bankOptions[0].id}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item">
                                                <div className="media align-items-center">
                                                    <div className="media-body">
                                                        <p className="small text-muted mb-0">userId</p>
                                                        <p className="mb-0">
                                                            {profileInfo.bankOptions[0].userId}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item">
                                                <div className="media align-items-center">
                                                    <div className="media-body">
                                                        <p className="small text-muted mb-0">fio</p>
                                                        <p className="mb-0">
                                                            {profileInfo.bankOptions[0].fio}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item">
                                                <div className="media align-items-center">
                                                    <div className="media-body">
                                                        <p className="small text-muted mb-0">Держатель карты</p>
                                                        <p className="mb-0">
                                                            {profileInfo.bankOptions[0].cardHolder}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-group-item">
                                                <div className="media align-items-center">
                                                    <div className="media-body">
                                                        <p className="small text-muted mb-0">Номер карты</p>
                                                        <p className="mb-0">
                                                            {profileInfo.bankOptions[0].cardNumber}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                        </>
                                    }
                                </ul>
                            </div>


                            <div className="card">
                                <ul className="list-group list-group-flush">
                                    {
                                        social !== undefined && Object.entries(social).map((soc) => {
                                            if (soc[1] !== '')
                                                return (
                                                    <>
                                                        {
                                                            soc[1] !== '' &&
                                                            <li className="list-group-item">
                                                                <div className="media align-items-center">
                                                                    <div className="media-body">
                                                                        <p className="small text-muted mb-0">{soc[0]}</p>
                                                                        <a
                                                                            className="font-size-sm font-weight-medium"
                                                                            href={"https://"+ soc[0] + '.com/' + soc[1]}
                                                                            target="_blank"
                                                                        >
                                                                            {soc[1]}
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        }
                                                    </>
                                                )
                                        })
                                    }

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

