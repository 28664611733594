import React from "react";
import {Route} from "react-router-dom";
import {Switch} from "react-router-dom";
import {SignInFunc} from "../components/SignIn/SignInFunc";
import {ChatsFunc} from "../components/Chats/ChatsFunc";
import {useHistory} from "react-router";
import Business from "../components/Business/Business";
import {Friends} from "../components/Friends/Friends";
import {FriendListFunc} from "../components/Friends/FriendList/FriendListFunc";
import {FriendRouter} from "../components/Friends/FriendList/FriendsRouter";
// сделать ветку в гите прод/дев (со всей фигней)
// здесь сделать проверку на наличие токена в локал-сторадж и активность его
// (текущая дата должна быть меньше даты смерти) и если нету - редирект на sign in и можно удалить, если устарел


export const Routing = React.memo(() => {
    // debugger
    const history = useHistory();
    const tokenDieTime = localStorage.getItem("tokenDieTime");
    const dateNow = Date.now();
    if (tokenDieTime) {
        if (dateNow > tokenDieTime) {
            localStorage.removeItem("tokenKey");
            localStorage.removeItem("tokenDieTime");
            history.push("/SignIn")
        }
    }
    if (localStorage.getItem("tokenKey") === null) {
        history.push("/SignIn")
    }
    return (
        <>
            <Switch>
                <Route path="/SignIn">
                    <SignInFunc/>
                </Route>
                <Route path={"/Business"} render={()=><Business/>} exact/>
                <Route path={"/Users"} render={()=><Friends/>}/>
                {/*<Route path={"/Users/"} render={()=><FriendListFunc/>} exact/>*/}

                {/*<Route path={"/Users/:hashUser"} render={()=><FriendListFunc hashUser={props.hashUser}/>} exact/>*/}
                <Route path="/">
                    <ChatsFunc/>
                </Route>

            </Switch>
        </>
    );
})
