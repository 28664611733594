import {API} from "../api/api";
import {loginErrorsReducerAC, error} from "./errors-reducer";

const initState = {
    isLoggedIn: false,
    waitingResponce: false,
    validInps: false
}

export const authReducer = (state = initState, action)=> {
    switch (action.type) {
        case 'login/SET-IS-LOGGED-IN': {
            return {...state, isLoggedIn: action.value}
        }
        case 'login/WAITING-RESPONCE': {
            return {...state, waitingResponce: action.value}
        }
        case 'login/VALID-INPS': {
            return {...state, validInps: action.value}
        }
        default:
            return state
    }
}

export const authLoginAC = (value) => {
    return {type: 'login/SET-IS-LOGGED-IN', value}
}
export const authLoginWaitingResponceAC = (value) => {
    return {type: 'login/WAITING-RESPONCE', value}
}
export const authLoginValidInpsAC = (value) => {
    return {type: 'login/VALID-INPS', value}
}
export const loginTC = (login, password) => (dispatch) => {
    dispatch(authLoginWaitingResponceAC(true))
    API.authLogin(login, password)
        .then((res) => {
            // debugger
            if(res.data.result) {
                // let dateNow = Date.now();
                let dateNow = Date.now() / 1000;
                console.log(dateNow)
                localStorage.setItem("tokenKey", res.data.result.access_token);
                localStorage.setItem("tokenKeyRefresh", res.data.result.refresh_token);
                localStorage.setItem("tokenKeyExpire", dateNow + res.data.result.durationAccess);
                localStorage.setItem("tokenKeyRefreshExpire", dateNow + res.data.result.durationRefresh);
                dispatch(authLoginAC(true))
                dispatch(loginErrorsReducerAC({}))
            }
            if(res.data.error) {
                dispatch(loginErrorsReducerAC(res.data.error))
                let state = window.store.getState();
                error(state.errors.message)
                dispatch(authLoginWaitingResponceAC(false))
                dispatch(authLoginValidInpsAC(true))
                setTimeout(() => {
                    dispatch(authLoginValidInpsAC(false))
                }, 3000)
            }
        })
}
export const logOutTC = () => (dispatch) => {
    dispatch(authLoginAC(false))
    dispatch(authLoginWaitingResponceAC(false))
}



