import React, {Component, useEffect} from "react";
import {ReactComponent as CallNowSvg} from "../../assets/media/icons/callnow.svg";
import {ReactComponent as PhoneIncomingSvg} from "../../assets/media/heroicons/solid/phone-incoming.svg";
import {useDispatch, useSelector} from "react-redux";
import {confirmBusinessTC, getBaDocsTC, getIsBusinessTC, rejectBusinessTC} from "../../Redux/business-reducer";
import {ThemeColor} from "../../Config/Config";
import Avatar from "react-avatar";
import {Link} from "react-router-dom";
import {LightgalleryItem, LightgalleryProvider} from "react-lightgallery";
import gallery from "../../assets/media/icons/photo.svg";
import {ReactComponent as GallerySvg} from "../../assets/media/icons/photo.svg";
import {ReactComponent as DeleteSvg} from "../../assets/media/icons/block.svg";
import {ReactComponent as ChatPlusSvg} from "../../assets/media/icons/chatplus.svg";
import photo2 from "../../assets/media/shared-photos/02.jpg";
import photo3 from "../../assets/media/shared-photos/03.jpg";
import NavBar from "../NavBar/NavBar";
import {ReactComponent as BackToList} from "../../assets/media/icons/backtolist.svg";
import {useWindowWidth} from "@react-hook/window-size";

export const Business = () => {
    const state = {
        themeColor: Boolean(localStorage.getItem("theme"))
            ? parseInt(localStorage.getItem("theme"))
            : ThemeColor.Light,
        isRemember: false,
        isRTL: Boolean(localStorage.getItem("rtl")),
    };
    useEffect(() => {
        let classList = document.body.classList;
        if (classList.length === 0 && state.themeColor === ThemeColor.Dark) {
            document.body.classList.add("dark-skin");
        }
    }, [state.themeColor])
    const dispatch = useDispatch()
    const businessRequest = useSelector(state => state.business.requests)
    const key = localStorage.getItem("tokenKey")
    const userDocs = useSelector(state => state.business.usersDocs)
    useEffect(() => {
        dispatch(getIsBusinessTC(key))
    }, [])
    const confirmBusines = (user) => {
        dispatch(confirmBusinessTC(key, user))
    }
    const rejectBusines = (user) => {
        dispatch(rejectBusinessTC(key, user))
    }

    useEffect(() => {
        Object.keys(businessRequest).map((user) => {
            dispatch(getBaDocsTC(key, user))
        })
    }, [businessRequest])
    // console.log(userDocs)

    const width = useWindowWidth()
    console.log(width)

    return (
        <div className={"main-layout"}>
            <NavBar activeMenu="business"/>
            <aside className="sidebar" style={{flex: 11}}>
                <div className="calls px-0" style={{flexDirection: 'column', paddingBottom: 20}}>
                    <div className="page-main-heading sticky-top py-2 px-3 mb-3">
                        <div className="pl-2 pl-xl-0">
                            <h5 className="font-weight-semibold" style={{marginTop: 10, marginBottom: 10}}>List of users
                                with a request for a business account</h5>
                        </div>
                    </div>
                    <div className="container-xl">
                        <div className="row calls-log">
                            <div className="col">
                                {
                                    Object.keys(businessRequest).map((user) => {
                                        let path = []
                                        userDocs[user] && Object.keys(userDocs[user]).map((document) => {
                                            path.push(userDocs[user][document].filePath)
                                        })
                                        return (
                                            <div className="card businessCard">
                                                <div className="card-body">
                                                    <div className="media"  style={{alignItems: 'center'}}>
                                                        <div className="avatar-primary mr-2">
                                                            <span>
                                                                <Avatar
                                                                    name={businessRequest[user].nickname}
                                                                    size={48}
                                                                    round
                                                                    src={'https://cdn.fanter.com/' + businessRequest[user].avatar}
                                                                />
                                                            </span>
                                                        </div>

                                                        <div className="media-body">
                                                            <h6>
                                                                {
                                                                    businessRequest[user].nickname
                                                                }
                                                            </h6>
                                                            <div
                                                                className="d-flex flex-column flex-sm-row align-items-sm-center align-items-start">
                                                                {width > 1000 &&
                                                                <p className="text-muted lengthDocs mb-0">
                                                                    Uploaded
                                                                    {
                                                                        userDocs[user] && userDocs[user].map((doc) => {
                                                                            return (
                                                                                <span>
                                                                                {
                                                                                    ' ' + userDocs[user].length + ' '
                                                                                }
                                                                            </span>
                                                                            )
                                                                        })
                                                                    }
                                                                    docs
                                                                </p>
                                                                }

                                                                <span className="d-none d-sm-block text-muted mx-2">
                                                                    •
                                                                </span>
                                                                <span style={{
                                                                    fontSize: 0.875 + 'rem',
                                                                    textDecoration: 'underline',
                                                                    cursor: 'pointer'
                                                                }} className="viewDocs text-muted mb-0">
                                                                    <LightgalleryProvider>
                                                                            {
                                                                                userDocs[user] && userDocs[user].map((doc) => {
                                                                                    return (
                                                                                        <LightgalleryItem
                                                                                            group={"group1"}
                                                                                            src={'https://cdn.fanter.com/' + doc.filePath}
                                                                                            thumb={'https://cdn.fanter.com/' + doc.filePath}
                                                                                        >
                                                                                            <span className="">
                                                                                                <GallerySvg
                                                                                                    style={{marginRight: 5}}/>View docs
                                                                                            </span>
                                                                                        </LightgalleryItem>
                                                                                    )
                                                                                })
                                                                            }
                                                                    </LightgalleryProvider>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {
                                                            width > 1000 ?
                                                                <div className="media-options">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-outline-danger mx-1"
                                                                        onClick={() => {
                                                                            rejectBusines(user)
                                                                        }}
                                                                    >
                                                                        Reject
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary mx-1"
                                                                        onClick={() => {
                                                                            confirmBusines(user)
                                                                        }}
                                                                    >
                                                                        Accept request
                                                                    </button>
                                                                </div> :
                                                                <div>
                                                                    <button className="btn btn-link text-muted" style={{padding: 2, margin: 2}} onClick={() => {
                                                                        rejectBusines(user)
                                                                    }}>
                                                                        <DeleteSvg className="hw-24" style={{color: '#ff337c'}}/>
                                                                    </button>
                                                                    <button className="btn btn-link text-muted" style={{padding: 2, margin: 2}} onClick={() => {
                                                                        confirmBusines(user)
                                                                    }}>
                                                                        <ChatPlusSvg className="hw-24" style={{color: '#44a675'}}/>
                                                                    </button>
                                                                </div>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        </div>
    );

}
export default Business;
