import React from "react";
import {Route, Switch} from "react-router-dom";

import {EmptyChat} from "./EmptyChat";
import {UserChatFunc} from "./UserChatFunc";

export const ChatHistory = React.memo((props) => {
  return (
      <>
          <Switch>
                <Route path={"/Chat/:chatId"} render={()=><UserChatFunc chatId={props.chatId}/>} exact/>
                <Route path={"/Chat/new"} render={()=><UserChatFunc/>} exact/>
                <Route path="/" render={()=><EmptyChat/>}/>
          </Switch>
      </>
  );
}
)
