import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Avatar from "react-avatar";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentUserTC} from "../../../../Redux/users-reducer";
import {useLocation} from "react-router";
import chatCreatorIcon from "../../../../assets/media/icons/chatCreatorsIcon2.svg"
import chatSupportIcon from "../../../../assets/media/icons/chatSupportIcon2.svg"
import {useWindowWidth} from "@react-hook/window-size";
import business from "../../../../assets/media/icons/business.svg"

const ChatMessage = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const path = location.pathname.split('/')
    useEffect(()=>{
        dispatch(setCurrentUserTC(path[2]))
    }, [])

    const setCurrentUser = () => {
        dispatch(setCurrentUserTC(props.creatorID))
    }

    const [state, setState] = useState(0);

    useEffect(() => {
        setState(1)
    }, [])

    // console.log(props.infoUsers)
    let newAvatarPath
    if (props?.infoUsers) {
        newAvatarPath = props.infoUsers.avatar.split('.')
    }
    const width = useWindowWidth()
    // console.log(props.chatType)
    return (
        <li
            className={
                "contacts-item friends " +
                (props.chatId && window.location.href.search(props.chatId) > 0
                    ? "active "
                    : " ") +
                (props.badge ? "unread" : "")
            }
            onClick={setCurrentUser}
        >
            <Link
                className="contacts-link chatList"
                to={'/Chat/' + props.chatId}
            >
                <span style={{width: 20, height: 20, position: 'absolute', top: 0, right: 3, borderRadius: 3}}>
                     {props.chatType === 'creator' ? <img src={chatSupportIcon} style={{width: 18, height: 18}} alt=""/> : <img src={chatCreatorIcon} style={{width: 18, height: 18}} alt=""/>}
                 </span>
                {/*{profile}*/}
                {/*<Avatar*/}
                {/*    style={{minWidth: 48, objectFit: 'cover'}}*/}
                {/*    name={state === 1 && props.infoUsers !== undefined &&  props.infoUsers.firstName + ' ' + props.infoUsers.lastName + ' ' + props.infoUsers.nickname}*/}
                {/*    src={state === 1 && props.infoUsers !== undefined && 'https://cdn.fanter.com/' + props.infoUsers.avatar}*/}
                {/*    size={48}*/}
                {/*    round*/}
                {/*/>*/}
                {
                    props.isBusiness === 1 &&
                    <div style={{position: 'absolute', marginLeft: -5, top: 15}}><img style={{width: 17, height: 17}} src={business} alt=""/></div>
                }
                {
                    props?.infoUsers &&
                    <Avatar
                        style={{minWidth: 48, objectFit: 'cover'}}
                        name={props.infoUsers.firstName + ' ' + props.infoUsers.lastName + ' ' + props.infoUsers.nickname}
                        // src={'https://cdn.fanter.com/' + props.infoUsers.avatar}
                        src={'https://cdn.fanter.com/' + newAvatarPath[0] + '-m.' + newAvatarPath[1]}
                        size={48}
                        round
                    />
                }
                <div className="contacts-content">
                    <div className="contacts-info">
                        <h6 className="chat-name text-truncate">
                            {
                                // state === 1 && props.infoUsers !== undefined && props.infoUsers.firstName + ' ' + props.infoUsers.lastName
                                props?.infoUsers && props.infoUsers.firstName + ' ' + props.infoUsers.lastName
                            }
                            {
                                // state === 1 && props.infoUsers !== undefined && props.infoUsers.firstName === '' && props.infoUsers.lastName === '' && props.nick
                                props?.infoUsers && props.infoUsers.firstName === '' && props.infoUsers.lastName === '' && props.nick
                            }
                        </h6>

                        <div className="chat-time" style={width > 600 ? { paddingRight: 8 } : { paddingRight: 20}}>
                            {props.lastMessageTime}
                        </div>
                    </div>
                    <div className="contacts-texts">
                        {props.MessageSvg ? props.MessageSvg : null}
                        <p className="text-truncate">{props.lastMessage}</p>
                        {props.endIcon ? (
                            <div className="d-inline-flex align-items-center ml-1">
                                {props.endIcon}
                            </div>
                        ) : null}
                        {props.badge ? (
                            <div className="badge badge-rounded badge-primary ml-1">
                                {props.badge}
                            </div>
                        ) : null}
                    </div>
                </div>
            </Link>
        </li>
    );
};
export default ChatMessage;
