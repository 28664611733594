import React, {useEffect, useState} from "react";
import {Dropdown, DropdownButton, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import {ReactComponent as SearchSvg} from "../../../assets/media/icons/search.svg";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentUserTC, setFilterChats, setFindedUsersTC, setNewChatUser} from "../../../Redux/users-reducer";
import Avatar from "react-avatar";
import {clearMessagesChangeChat, selectNewChatTypeAC} from "../../../Redux/messages-reducer";
import uuid from "react-uuid";
// Start a conversion
export const StartConversation = (props) => {

    const [findState, setFindState] = useState('')
    const key = localStorage.getItem("tokenKey")
    const dispatch = useDispatch()
    const findedUsers = useSelector(state => state.usersPage.findedUsers)
    const selectNewChatType = useSelector(state => state.messages.selectNewChatType)
    useEffect(() => {
        dispatch(setFindedUsersTC(key, 'b'))
    }, [])
    const setModalShow = () => {
        props.hideNewChat()
    }
    const searchUsers = (e) => {
        setFindState(e.target.value)
        dispatch(setFindedUsersTC(key, e.target.value))
    }
    const onClickHandler = (user, hashUser) => {
        dispatch(setNewChatUser(user))
        dispatch(setCurrentUserTC(hashUser))
        dispatch(clearMessagesChangeChat())
        setModalShow()
    }

    const [selectedTitle, setSelectedTitle] = useState(selectNewChatType);

    const handleChange = (eventKey) => {
        setSelectedTitle(eventKey)
        dispatch(selectNewChatTypeAC(eventKey))
    };
    return (
        <Modal show={true} scrollable={true} onHide={setModalShow} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h5>New Chat</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0 hide-scrollbar">
                <div className="row">
                    <div className="col-12">
                        <form className="form-inline w-100 p-2 border-bottom" style={{justifyContent: 'space-between'}}>
                            <div>
                                <DropdownButton
                                    className="border-0 f-16 p-0 min-w-150 cut-texts"
                                    variant="outline-default"
                                    title={selectedTitle}
                                    onSelect={handleChange}
                                >
                                    <Dropdown.Item eventKey={'support'}>
                                        support
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey={'creator'} >
                                        creator
                                    </Dropdown.Item>
                                </DropdownButton>
                            </div>
                            <div className="input-group bg-light" style={{width: `calc(100% - 120px)`}}>
                                <input
                                    type="text"
                                    className="form-control search border-right-0 pr-0"
                                    placeholder="Search..."
                                    value={findState}
                                    onChange={searchUsers}
                                />
                                <div className="input-group-append">
                                    <div
                                        className="input-group-text border-left-0"
                                        role="button"
                                    >
                                        <SearchSvg/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="col-12">
                        <ul className="list-group list-group-flush">
                            {
                                findedUsers && findedUsers.map((user) => {
                                    console.log(user)
                                    return (
                                        <li className="list-group-item" key={uuid()}>
                                            <div className="media">
                                                <div className="avatar mr-2">
                                                    <Avatar
                                                        size={48}
                                                        name={
                                                            user.email
                                                        }
                                                        src={'https://cdn.fanter.com/' + user.avatar}
                                                        round
                                                    />
                                                </div>
                                                <div className="media-body">
                                                    <h6 className="text-truncate">
                                                        {
                                                            selectNewChatType == 'creator' &&
                                                            <Link
                                                                onClick={() => {
                                                                    onClickHandler(user, user.hashUser)
                                                                }}
                                                                className="text-reset"
                                                                to={
                                                                    user.creatorChatId == 0 ? '/Chat/new' : '/Chat/' + user.creatorChatId
                                                                }
                                                            >
                                                                {user.firstName !== '' ? user.firstName + ' ' + user.lastName : user.email}
                                                            </Link>
                                                        }
                                                        {
                                                            selectNewChatType == 'support' &&
                                                            <Link
                                                                onClick={() => {
                                                                    onClickHandler(user, user.hashUser)
                                                                }}
                                                                className="text-reset"
                                                                to={
                                                                    // '/Chat/' + user.supportChatId
                                                                    user.supportChatId == 0 ? '/Chat/new' : '/Chat/' + user.supportChatId
                                                                }
                                                            >
                                                                {user.firstName !== '' ? user.firstName + ' ' + user.lastName : user.email}
                                                            </Link>
                                                        }
                                                    </h6>
                                                    {/*<p className="text-muted mb-0">Online</p>*/}
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
