import React from "react";
import {Link} from "react-router-dom";
import {ReactComponent as LocationMarker} from "../../../assets/media/heroicons/solid/location-marker.svg";
import Avatar from "react-avatar";
import {useLocation} from "react-router";
import {getProfileInfoTC, setBusinessDocsStatus} from "../../../Redux/users-reducer";
import {useDispatch, useSelector} from "react-redux";

const Friend = (props) => {
    const location = useLocation();
    const path = location.pathname.split('/')
    const key = localStorage.getItem("tokenKey")
    const dispatch = useDispatch()
    const businessDocsStatus = useSelector(state => state.usersPage.profileInfo.isRequestUploadDoc)
    dispatch(setBusinessDocsStatus(businessDocsStatus))
    return (

        <li onClick={() => {dispatch(getProfileInfoTC(key, props.hashUser))}} className={"contacts-item " + (props.hashUser ===  path[2] ? "active" : "")}>
            <Link to={'/Users/' + props.hashUser} className="contacts-link">
                <div className="avatar">
                    <Avatar
                        style={{minWidth: 48, objectFit: 'cover'}}
                        name={props.avatar == '' && props.email}
                        src={props.avatar !== '' && 'https://cdn.fanter.com/' + props.avatar}
                        size={48}
                        round
                    />
                    {/*{*/}
                    {/*    props.avatar == '' &&*/}
                    {/*    <Avatar*/}
                    {/*        style={{minWidth: 48, objectFit: 'cover'}}*/}
                    {/*        name={props.email}*/}
                    {/*        src={'https://cdn.fanter.com/' + props.avatar}*/}
                    {/*        size={48}*/}
                    {/*        round*/}
                    {/*    />*/}
                    {/*}*/}
                </div>
                <div className="contacts-content">
                    <div className="contacts-info">
                        <h6 className="chat-name text-truncate">
                            {props.firstName + ' ' + props.lastName}
                            {props.firstName === '' && props.lastName === '' && props.nickName}
                            {props.firstName === '' && props.lastName === '' && props.nickName === '' && props.email}
                        </h6>
                    </div>
                    <div className="contacts-texts">
                        <LocationMarker className="hw-16 text-muted mr-1"/>
                        <p className="text-muted mb-0">
                            From
                            {props.locationCountry && ' ' + props.locationCountry + ' '}
                            {props.locationTown && ' ' + props.locationTown}
                            {!props.locationCountry && !props.locationTown && ' planet Earth'}
                        </p>
                    </div>
                </div>
            </Link>
        </li>
    );
};
export default Friend;
