import {API} from "../api/api";

let initialState = {
    messages: [],
    users: {},
    messagesIsLoad: false,
    newChatId: '',
    newMessage: '',
    newToken: '',
    createNewChatId: '',
    selectNewChatType: 'support'
};

export const messagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_MESSAGES': {
            return {...state, messages: action.messages, users: action.users}
        }
        case 'LOADING_MESSAGES': {
            return {...state, messagesIsLoad: action.value}
        }
        case 'SEND_MESSAGE': {
            return {[0]: [action.message],...state}
        }
        case 'SELECT_NEW_CHAT': {
            return {...state, selectNewChatType: action.newChatType}
        }
        case 'CREATE_NEW_CHAT': {
            return {...state, createNewChatId: action.nChatId}
        }
        case 'CHANGE_CHAT_CLEAR_MESSAGES': {
            return {...state, messages: []}
        }
        case 'EDIT_MESSAGE': {
            let newMes = state.messages.map((mes) => {
                if (mes.id === action.id) {
                    mes.message = action.message
                    mes.isEdit = action.isEdit
                }
                return mes
            })
            return {...state, messages: newMes}
        }
        default:
            return state;
    }
}

export const editMessageAC = (id, message, isEdit) => ({type: 'EDIT_MESSAGE', id, message, isEdit})
export const clearMessagesChangeChat = () => ({type: 'CHANGE_CHAT_CLEAR_MESSAGES'})
export const setMessages = (messages, users) => ({type: 'SET_MESSAGES', messages, users})
export const sendMessages = (message) => ({type: 'SEND_MESSAGE', message})
export const messagesIsLoad = (value) => ({type: 'LOADING_MESSAGES', value})
export const selectNewChatTypeAC = (newChatType) => ({type: 'SELECT_NEW_CHAT', newChatType})
export const createNewChat = (nChatId) => ({type: 'CREATE_NEW_CHAT', nChatId})

export const getMessagesTC = (chatId, tokenKey) => (dispatch) => {
    // dispatch(setMessages({}))
    API.getMessages(chatId, tokenKey)
        .then((res) => {
            // debugger
            if(res.data.result) {
                dispatch(setMessages(res.data.result.messages, res.data.result.users))
            }
        })
        dispatch(messagesIsLoad(true))
}


export const sentMessageTC = (chatId, tokenKey, message) => (dispatch) => {
    // debugger
    API.sendMessage(chatId, tokenKey, message)
        .then((res) => {
            dispatch(sendMessages(message))
        })
}

export const createNewChatTC = (tokenKey, hashUser, chatType, message, history) => (dispatch) => {
    let chatId = undefined
    API.createChat(tokenKey, hashUser, chatType)
        .then((res) => {
            // dispatch(createNewChat(res.data.result.chatId))
            chatId = res.data.result.chatId
            return API.sendMessage(res.data.result.chatId, tokenKey, message)
        })
        .then(() => {
            history('/Chat/' + chatId)
        })
}




























