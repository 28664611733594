import React, {Component, useEffect} from "react";
import {ThemeColor} from "../../Config/Config";
import NavBar from "../NavBar/NavBar";

import {FriendListFunc} from "./FriendList/FriendListFunc";
import {FriendRouter} from "./FriendList/FriendsRouter";
import {useLocation} from "react-router";

export const Friends = () => {
    const state = {
        themeColor: Boolean(localStorage.getItem("theme"))
            ? parseInt(localStorage.getItem("theme"))
            : ThemeColor.Light,
        isRemember: false,
        isRTL: Boolean(localStorage.getItem("rtl")),
    };
    useEffect(() => {
        let classList = document.body.classList;
        if (classList.length === 0 && state.themeColor === ThemeColor.Dark) {
            document.body.classList.add("dark-skin");
        }
    }, [state.themeColor])
    const location = useLocation();
    const path = location.pathname.split('/')
    return (
        <div className="chats-tab-open h-100">
            <div className={"main-layout h-100"}>
                <NavBar activeMenu="users"/>
                <FriendListFunc/>
                <FriendRouter hashUser={path[2] != '' && path[2]}/>
                <div className="backdrop"></div>
            </div>
        </div>
    );
}
