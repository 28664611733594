import React, {useState} from "react";
import {StartConversation} from "../../Modals/StartConversation/StartConversation";
import flogo from '../../../../src/assets/media/flogo.svg'


export const EmptyFriends = () => {

    return (
        <>
            <main className="main">
                <div className="chats">
                    <div className="d-flex flex-column justify-content-center text-center h-100 w-100">
                        <div className="container">
                            <div className="avatar avatar-lg mb-2">
                                <img className="avatar-img" src={flogo} alt=""/>
                            </div>
                            <h5>Support</h5>
                            <p className="text-muted">
                                Please select a user to view info for him
                            </p>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}
