import {API} from "../api/api";
import {confirmBusinessTC, rejectBusinessTC} from "./business-reducer";

let initialState = {
    users: [],
    infoUsers: {},
    currentUser: '',
    filterChats: 'all',
    findedUsers: [],
    newChatUser: {},
    usersList: [],
    profileInfo: {},
    businesDocStatus: null,
    isOnline: {},
    userIsOnline: {}
};

export const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_USERS': {
            return {...state, users: action.users}
        }
        case 'SET_INFOUSERS': {
            return {...state, infoUsers: action.infoUsers}
        }
        case 'SET_CURRENT_USER': {
            if (action.currentUser) {
                return {...state, currentUser: action.currentUser}
            }
            return state
        }
        case 'SET_FILTER_CHAT': {
            return {...state, filterChats: action.selector}
        }
        case 'SET_FINDED_USERS': {
            return {...state, findedUsers: action.users}
        }
        case 'SET_NEW_CHAT_USER': {
            return {...state, newChatUser: action.user}
        }
        case 'SET_USERS_LIST': {
            return {...state, usersList: action.users}
        }
        case 'GET_PROFILE_INFO': {
            return {...state, profileInfo: action.user}
        }
        case 'SET_BUSINES_DOC_STATUS': {
            return {...state, businesDocStatus: action.status}
        }
        case 'SET_ONLINE_STATUSES': {
            let addMore = Object.assign({...state.isOnline}, {[action.hashUser]: action.status})
            return {...state, isOnline: addMore}
        }
        case 'SET_ONLINE_USER': {
            return {...state, userIsOnline: action.status}
        }
        default:
            return state;
    }
}


export const setUsers = (users) => ({type: 'SET_USERS', users})
export const setInfoUsers = (infoUsers) => ({type: 'SET_INFOUSERS', infoUsers})
export const setCurrentUser = (currentUser) => ({type: 'SET_CURRENT_USER', currentUser})
export const setFilterChats = (selector) => ({type: 'SET_FILTER_CHAT', selector})
export const findedUsers = (users) => ({type: 'SET_FINDED_USERS', users})
export const setNewChatUser = (user) => ({type: 'SET_NEW_CHAT_USER', user})
export const setUsersList = (users) => ({type: 'SET_USERS_LIST', users})
export const getProfileInfo = (user) => ({type: 'GET_PROFILE_INFO', user})
export const setBusinessDocsStatus = (status) => ({type: 'SET_BUSINES_DOC_STATUS', status})
export const isOnline = (status, hashUser) => ({type: 'SET_ONLINE_STATUSES', status, hashUser})
export const userIsOnline = (status) => ({type: 'SET_ONLINE_USER', status})

class RefreshProxy  {
    resolve () {return null}
    getValue() {return new Promise((resolve) =>{
        this.resolve = resolve
    })}
    isRefreshing= false
    setValue(value){
        this.isRefreshing = false
        this.resolve(value)
    }
}

const refreshProxy = new RefreshProxy();
export const setTokens = (result) => {
    let dateNow = Date.now() / 1000;
    localStorage.setItem("tokenKey", result.access_token);
    localStorage.setItem("tokenKeyRefresh", result.refresh_token);
    localStorage.setItem("tokenKeyExpire", dateNow + result.durationAccess);
    localStorage.setItem("tokenKeyRefreshExpire", dateNow + result.durationRefresh);
}
export const getTokens = () => {
    const key = localStorage.getItem("tokenKey")
    const expKey = parseInt(localStorage.getItem("tokenKeyExpire"), 10)
    // console.log(expKey)
    return {key, expKey}
}
export const checkTokens = (expKey) => {
    let dateNow = Date.now() / 1000;
    const expKeyNumb = parseInt(expKey, 10)
    const expKeyMinusFive = (expKeyNumb - 300)
    // console.log(dateNow, expKeyMinusFive)
    return dateNow < expKeyMinusFive
}
export const refreshTokens = async (tokenKey) => {
    refreshProxy.isRefreshing = true
    const res = await API.refreshTokens(tokenKey)
    if (res.data.error) {
        window.location.href = '/SignIn';
        document.location.reload()
    }
    let dateNow = Date.now() / 1000;
    localStorage.setItem("tokenKey", res.data.result.access_token);
    localStorage.setItem("tokenKeyRefresh", res.data.result.refresh_token);
    localStorage.setItem("tokenKeyExpire", dateNow + res.data.result.durationAccess);
    localStorage.setItem("tokenKeyRefreshExpire", dateNow + res.data.result.durationRefresh);
    refreshProxy.setValue(res.data.result)
    return res.data.result
}
export const getActualToken = async () => {
    const {key, expKey} = getTokens()
    if (!key) {
        return ''
    }
    // console.log(checkTokens(expKey))
    if (checkTokens(expKey)) {
        return key
    }

    if(refreshProxy.isRefreshing){
        const res = await refreshProxy.getValue();
        return res.access_token
    }
    const refreshToken = localStorage.getItem("tokenKeyRefresh")
    const res = await refreshTokens(refreshToken)
    setTokens(res)
    return res.access_token
}


export const getUsersTC = (filter, offset) => async (dispatch, getState) => {
    const tokenKey = await getActualToken()
    API.getUsers(tokenKey, filter, offset)
        .then((res) => {
            // debugger
            if (res) {
                let state = getState()
                if (res?.data?.result?.chats[0]?.lastMessageText === state?.usersPage?.users[0]?.lastMessageText) {
                    return
                }
                dispatch(setUsers(res.data.result.chats))
                dispatch(setInfoUsers(res.data.result.users))
            }
        })
}

export const setIsOnlineUsers = (hashUser) => (dispatch) => {
    // dispatch(pushOnlineHash(hashUser))
    API.isOnline(hashUser)
        .then((res) => {
            // debugger
            dispatch(isOnline(res?.data?.result?.lastOnline, hashUser))
        })
}

export const setUserIsOnline = (user) => (dispatch) => {
    API.isOnline(user)
        .then((res) => {
            // debugger
            dispatch(userIsOnline(res?.data?.result?.lastOnline))
        })
}

export const setCurrentUserTC = (currentUser) => (dispatch) => {
    // debugger
    dispatch(setCurrentUser(currentUser))
}

export const setFindedUsersTC = (tokenKey, str) => (dispatch) => {
    API.findUsersForChats(tokenKey, str)
        .then((res) => {
            if (res) {
                dispatch(findedUsers(res.data.result))
            }
        })
}
export const setUsersListTC = (tokenKey, str) => (dispatch) => {
    API.findUsersForChats(tokenKey, str)
        .then((res) => {
            if (res) {
                dispatch(setUsersList(res.data.result))
            }
        })
}

export const getProfileInfoTC = (tokenKey, hashUser) => (dispatch) => {
    API.getProfileForAdmin(tokenKey, hashUser)
        .then((res) => {
            // debugger
            dispatch(getProfileInfo(res.data.result))
        })
}

export const setBusinessDocsStatusTC = (tokenKey, hashUser, status) => (dispatch) => {
    API.changeUploadedDocsState(tokenKey, hashUser, status)
        .then((res) => {
            if (res.data.result) {
                dispatch(setBusinessDocsStatus(status))
            }
        })
        .then(() => {
            if (status === 3) {
                dispatch(confirmBusinessTC(tokenKey, hashUser))
            }
            if (status === 4) {
                dispatch(rejectBusinessTC(tokenKey, hashUser))
            }
        })
}

export default usersReducer;
