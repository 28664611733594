import React from "react";
import { Link } from "react-router-dom";
// import { ReactComponent as AppIconSvg } from "../../assets/media/icons/appicon.svg";
import { ReactComponent as ChatsSvg } from "../../assets/media/icons/chats.svg";
import { ReactComponent as CallsSvg } from "../../assets/media/icons/calls.svg";
import { ReactComponent as FriendsSvg } from "../../assets/media/icons/friends.svg";
import { ReactComponent as DocsSvg} from "../../assets/media/icons/profiledocuments.svg";
// import { ReactComponent as AccountSvg } from "../../assets/media/icons/accounts.svg";
// import { ReactComponent as AccountSvg } from "../../assets/media/icons/accounts.svg";
import flogo from '../../assets/media/flogo.svg'
import logout from '../../assets/media/icons/logout.svg'
import {useHistory} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {logOutTC} from "../../Redux/auth-reducer";
import Radium, { StyleRoot } from 'radium';

function NavBar(props) {
    const history = useHistory();
    const dispatch = useDispatch()
    const logOut = () => {
        localStorage.removeItem("tokenKey");
        localStorage.removeItem("tokenDieTime");
        history.push("/SignIn")
        dispatch(logOutTC())
    }

    const navbarStyle = {
        width: '40px',
        '@media (maxWidth: 1199px)': {
            width: '25px',
        },
    }

  return (
      // <StyleRoot>
    <div className="navigation navbar navbar-light bg-primary">
      <Link className="d-none d-xl-block bg-light rounded p-1" to="/">
        {/*<AppIconSvg />*/}
          <img src={flogo} style={navbarStyle} alt=""/>
      </Link>

      <ul
        className="nav nav-minimal flex-row flex-grow-1 justify-content-between flex-xl-column justify-content-xl-center"
        id="mainNavTab"
        role="tablist"
        // style={{justifyContent: 'space-around', paddingLeft: 50, paddingRight: 50}}
      >
        <li className="nav-item">
          <Link
            className={
              "nav-link p-0 py-xl-3 " +
              (props.activeMenu === "chat" ? "active" : "")
            }
            id="chats-tab"
            to="/"
            title="Chats"
          >
            <ChatsSvg />
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              "nav-link p-0 py-xl-3 " +
              (props.activeMenu === "business" ? "active" : "")
            }
            id="business-tab"
            to="/Business"
            title="Business"
          >
            <DocsSvg />
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={
              "nav-link p-0 py-xl-3 " +
              (props.activeMenu === "users" ? "active" : "")
            }
            id="users-tab"
            to="/Users"
            title="Users"
          >
            <FriendsSvg />
          </Link>
        </li>
          <Link className={'d-xl-none'} to="/SignIn" onClick={logOut}>
              <img src={logout} alt=""/>
          </Link>
      </ul>
        <Link className={'d-none d-xl-block'} to="/SignIn" onClick={logOut}>
            <img src={logout} alt=""/>
        </Link>
    </div>
      /*</StyleRoot>*/
  );
}

// export default  Radium(NavBar);
export default  NavBar;
