import React, {useEffect} from "react";
import {ThemeColor} from "../../Config/Config";
import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {loginTC} from "../../Redux/auth-reducer";
import {useHistory} from "react-router";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// SignIn component
export const SignInFunc = React.memo(() => {
    const history = useHistory();

    // что бы избавиться от проверок - нужно сделать кастомный хук и подключать ее на все страницы для отображения темы
    const state = {
        themeColor: Boolean(localStorage.getItem("theme"))
            ? parseInt(localStorage.getItem("theme"))
            : ThemeColor.Light,
        isRemember: false,
        isRTL: Boolean(localStorage.getItem("rtl")),
    };
    useEffect(() => {
        let classList = document.body.classList;
        if (classList.length === 0 && state.themeColor === ThemeColor.Dark) {
            document.body.classList.add("dark-skin");
        }
    }, [])

    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            login: '',
            password: '',
        },
        validate: values => {

        },
        onSubmit: values => {
            formik.resetForm()
            dispatch(loginTC(values.login, values.password))
        },
    })

    const isLogin = useSelector(state => state.auth.isLoggedIn)
    const isWaiting = useSelector(state => state.auth.waitingResponce)
    const isValid = useSelector(state => state.auth.validInps)
    useEffect(() => {
        if (isLogin) {
            history.push("/")
        }
    }, [isLogin])

    const tokenDieTime = localStorage.getItem("tokenDieTime");
    const dateNow = Date.now();
    if (dateNow < tokenDieTime) {
        history.push("/")
    }

    return (
        <div className={"main-layout card-bg-1"}>
            <div className="container d-flex flex-column">
                <div className="row no-gutters text-center align-items-center justify-content-center min-vh-100">
                    <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                        <h1 className="font-weight-bold">Sign in</h1>
                        <form className="mb-3" onSubmit={formik.handleSubmit}>
                            <div className="form-group">
                                <label className="sr-only">Email Address</label>
                                <input
                                    type="text"
                                    className="form-control form-control-md"
                                    placeholder="Enter your email"
                                    {...formik.getFieldProps('login')}
                                    style={isValid ? {border: '1px solid #e74c3c'} : {}}
                                />

                            </div>
                            <div className="form-group">
                                <label className="sr-only">Password</label>
                                <input
                                    type="password"
                                    className="form-control form-control-md"
                                    placeholder="Enter your password"
                                    {...formik.getFieldProps('password')}
                                    style={isValid ? {border: '1px solid #e74c3c'} : {}}
                                />
                            </div>
                            <button
                                disabled={isWaiting}
                                type={'submit'}
                                className="btn btn-primary btn-lg btn-block text-uppercase font-weight-semibold"
                            >
                                Sign in
                            </button>
                            <ToastContainer/>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
})
