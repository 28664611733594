import React, {useEffect} from "react";
import ChatMessage from "./Message/ChatMessage";
import moment from "moment";
import 'moment/locale/ru';
import ChatAction from "../../ChatAction/ChatAction";
import ChatFilter from "../../FilterDropdowns/ChatFilter";
import {useDispatch, useSelector} from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {getUsersTC} from "../../../Redux/users-reducer";

function isToday(momentDate) {
    return momentDate.isSame(moment(), 'd');
}

export const ChatListFunc = React.memo((props) => {

    const dispatch = useDispatch()
    const infoUsers = useSelector(state => state.usersPage.infoUsers)
    const users = useSelector(state => state.usersPage.users)
    const key = localStorage.getItem("tokenKey")
    const filterChats = useSelector(state => state.usersPage.filterChats)

    const fetchMoreUsers = () => {
        dispatch(getUsersTC(key, filterChats, 100))
    }
// debugger
    return (
        <aside className="sidebar">

            <div className="tab-content" id="tab-contentInf">
                <div className="tab-pane active" id="chats-content">
                    <div className="d-flex flex-column h-100">
                        <div className="hide-scrollbar h-100" id="chatContactsList">
                            <div className="sidebar-header sticky-top p-2">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="font-weight-semibold mb-0 p-2">Chats</h5>
                                    <ChatAction />
                                </div>
                                <ChatFilter/>
                            </div>
                            <ul
                                className="contacts-list"
                                id="chatContactTab"
                                data-chat-list=""
                            >

                                {/*dataLength={contentData?.items.length ?? 0}*/}
                                {/*next={fetchData}*/}
                                {/*hasMore*/}
                                {/*loader={<span> </span>}*/}
                                {/*className={cn.infinityScroll}*/}
                                {/*scrollableTarget="timeline">*/}
                                {/*<InfiniteScroll*/}
                                {/*    // dataLength={100}*/}
                                {/*    dataLength={50}*/}
                                {/*    next={fetchMoreUsers}*/}
                                {/*    hasMore*/}
                                {/*    // inverse*/}
                                {/*    loader={<h4>Loading...</h4>}*/}
                                {/*    scrollableTarget="tab-contentInf"*/}
                                {/*>*/}
                                {
                                    props.users.map((user) => {
                                        // console.log(props.infoUsers)
                                        // console.log(infoUsers[user.creatorId].nickname)
                                        return <ChatMessage
                                            chatId={user.chatId}
                                            key={user.chatId}
                                            uname={user.name}
                                            nick={infoUsers[user.creatorId]?.nickname}
                                            lastMessage={user.lastMessageText}
                                            lastMessageTime={
                                                isToday(moment.unix(user.lastMessageTStamp)) ?
                                                    moment.unix(user.lastMessageTStamp).format("HH:mm") :
                                                    moment.unix(user.lastMessageTStamp).locale("ru").format("L")
                                            }
                                            creatorID={user.creatorId}
                                            infoUsers={infoUsers[user.creatorId]}
                                            chatType={user.chatName}
                                            isBusiness={infoUsers?.[user.creatorId]?.isBusiness}
                                        />
                                    })
                                }
                                {/*</InfiniteScroll>*/}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    );
})


