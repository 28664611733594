import {toast} from 'react-toastify';
import React from "react";

const initState = {}

export const errorsReducer = (state = initState, action)=> {
    switch (action.type) {
        case 'login/AUTH-ERROR': {
            let newState = {...state}
            newState = action.error
            return newState
        }
        default:
            return state
    }
}

export const loginErrorsReducerAC = (error) => {
    return {type: 'login/AUTH-ERROR', error}
}


export const error = (error) => toast.error(error, {
    autoClose: 3000,
    theme: "colored"
})


