import {applyMiddleware, combineReducers, createStore} from "redux";
import usersReducer from "./users-reducer";
import thunkMiddleware from "redux-thunk";
import {authReducer} from "./auth-reducer";
import {messagesReducer} from "./messages-reducer";
import {errorsReducer} from "./errors-reducer";
import {businessReducer} from "./business-reducer";

let reducers = combineReducers({
    usersPage: usersReducer,
    auth: authReducer,
    messages: messagesReducer,
    errors: errorsReducer,
    business: businessReducer
});

let store = createStore(reducers, applyMiddleware(thunkMiddleware));

window.store = store;

export default store;
