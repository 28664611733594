import React, {useState} from "react";
import {StartConversation} from "../../Modals/StartConversation/StartConversation";
import flogo from '../../../../src/assets/media/flogo.svg'


export const EmptyChat = () => {
    const [state, setState] = useState(false);
    const toggleNewChat = () => {
      setState(!state);
    };
    return (
        <>
          {state ? (
              <StartConversation hideNewChat={toggleNewChat} />
          ) : null}
          <main className="main">
            <div className="chats">
              <div className="d-flex flex-column justify-content-center text-center h-100 w-100">
                <div className="container">
                  <div className="avatar avatar-lg mb-2">
                    <img className="avatar-img" src={flogo} alt=""/>
                  </div>
                  <h5>Welcome, Support!</h5>
                  <p className="text-muted">
                    Please select a chat to start messaging.
                  </p>
                  <button
                      className="btn btn-primary no-box-shadow"
                      type="button"
                      data-toggle="modal"
                      data-target="#startConversation"
                      onClick={toggleNewChat}
                  >
                    Create new chat
                  </button>
                </div>
              </div>
            </div>
          </main>
        </>
    );
}
