import React, { Component } from "react";
import {Routing} from "./Routing/Routing";
import { ThemeColor } from "./Config/Config";
import { StyleRoot } from 'radium';


class App extends Component {
  selectThemeColor = (color) => {
    localStorage.setItem("theme", color);
  };
  componentWillMount() {
    this.selectThemeColor(ThemeColor.Dark)
  }
  render() {
    return <>
      {/*<StyleRoot>*/}
        <Routing/>
      {/*</StyleRoot>*/}
      </>
      ;
  }
}

export default App;
