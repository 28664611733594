import React from "react";
import {Route, Switch} from "react-router-dom";
import {FriendProfile} from "../FriendProfile/FriendProfile";
import {EmptyFriends} from "./EmptyFriends";

export const FriendRouter = React.memo((props) => {
        return (
            <>
                <Switch>
                    <Route path={"/Users/:hashUser"} render={()=><FriendProfile hashUser={props.hashUser}/>}/>
                    <Route path={"/Users/"}render={()=><EmptyFriends/>}/>
                </Switch>
            </>
        );
    }
)
