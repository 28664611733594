import * as axios from "axios";





export const API = {
    authLogin(login, password) {

        const params = {
            "id": "1",
            "method": "auth.createAdminKey",
            "params": {
                "email": login,
                "password": password
            },
            "jsonrpc": "2.0"
        }
        return axios.post('https://a.fanter.com/api/?auth.createAdminKey', params)
    },
    refreshTokens(tokenKey) {
        const params = {
            "id": "1",
            "method": "auth.refreshAdminKey",
            "params": {
                "refresh_token": tokenKey,
            },
            "jsonrpc": "2.0"
        }
        return axios.post('https://a.fanter.com/api/?auth.refreshAdminKey', params)
    },
    getUsers(tokenKey, chatType, offset) {
        const body = {
            "id": "1",
            "method": "chat.getChatList",
            "params": {
                "chatType": chatType,
                "offset": offset
            },
            "jsonrpc": "2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?chat.getChatList', body, {headers})
    },
    getMessages(chatID, tokenKey) {
        const body = {
            "id": "1",
            "method": "chat.getChatMessagesList",
            "params": {
                "id": chatID
            },
            "jsonrpc": "2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?chat.getChatMessagesList', body, {headers})
    },
    sendMessage(chatId, tokenKey, message, nickname, chatType) {
        const body = {
            "id": "1",
            "method":"chat.sendMessageFromSupport",
            "params":{
                "message": message,
                "accFrom": "01e7a7682d8a06e748814b9f636d697f81bc3057",
                "chatId": chatId,
                "nickname": nickname,
                "chatType": chatType
            },
            "jsonrpc":"2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?chat.sendMessageFromSupport', body, {headers})
    },
    editMessage(tokenKey, messageId, message) {
        const body = {
            "id": "1",
            "method":"chat.editMessage",
            "params":{
                "messageId": messageId,
                "message": message,
            },
            "jsonrpc":"2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?chat.editMessage', body, {headers})
    },
    createChat(tokenKey, hashUser, chatType) {
        const body = {
            "id": "1",
            "method":"chat.createChat",
            "params":{
                "hashUser": hashUser,
                "chatType": chatType
            },
            "jsonrpc":"2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?chat.createChat', body, {headers})
    },
    findUsersForChats(tokenKey, str) {
        const body = {
            "id": "1",
            "method":"user.findUserForChats",
            "params":{
                "searchStr" : str
            },
            "jsonrpc":"2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?user.findUserForChats', body, {headers})
    },
    getIsBusinessRequests(tokenKey) {
        const body = {
            "id": "1",
            "method":"user.getIsBusinessRequests",
            "params":{
            },
            "jsonrpc":"2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?user.getIsBusinessRequests', body, {headers})
    },
    commitIsBusiness(tokenKey, hashUser) {
        const body = {
            "id": "1",
            "method":"user.commitIsBusiness",
            "params":{
                "hashUser": hashUser
            },
            "jsonrpc":"2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?user.commitIsBusiness', body, {headers})
    },
    declineIsBusiness(tokenKey, hashUser) {
        const body = {
            "id": "1",
            "method":"user.declineIsBusiness",
            "params":{
                "hashUser": hashUser
            },
            "jsonrpc":"2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?user.declineIsBusiness', body, {headers})
    },
    getUploadedDocs(tokenKey, hashUser) {
        const body = {
            "id": "1",
            "method":"user.getUploadedDocs",
            "params":{
                "hashUser": hashUser
            },
            "jsonrpc":"2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?user.getUploadedDocs', body, {headers})
    },
    getProfileForAdmin(tokenKey, hashUser) {
        const body = {
            "id": "1",
            "method":"user.getProfileForAdmin",
            "params":{
                "hashUser": hashUser,
                // "offset": 0,
            },
            "jsonrpc":"2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?user.getProfileForAdmin', body, {headers})
    },
    changeUploadedDocsState(tokenKey, hashUser, status) {
        const body = {
            "id": "1",
            "method":"user.changeUploadedDocsState",
            "params":{
                "hashUser": hashUser,
                "stateBankDoc": status,
            },
            "jsonrpc":"2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?user.changeUploadedDocsState', body, {headers})
    },
    isOnline(hashUser) {
        const body = {
            "method":"profile.isOnline",
            "params":{
                "hashUser": hashUser,
            },
            "id":1,
            "jsonrpc":"2.0"
        }
        return axios.post('https://api.fanter.com/?profile.isOnline', body)
    },
    setFraud(tokenKey, hashUser, fraudScore) {
        const body = {
            "method":"user.setFraud",
            "params":{
                "hashUser": hashUser,
                fraudScore
            },
            "id":1,
            "jsonrpc":"2.0"
        }
        const headers = {
            'Authorization': 'Bearer ' + tokenKey,
        };
        return axios.post('https://a.fanter.com/api/?user.setFraud', body, {headers})
    },
}

